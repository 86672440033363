export default function LoadingSpinner({ size = "", color = "" }: any) {
  if (size === "lg" || size === "xl") {
    size = "big-spinner";
  }
  if (color === "blue") {
    color = "blue-spinner";
  }

  return <div className={`loading-spinner ${size} ${color}`}></div>;
}
