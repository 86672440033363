import { isString } from "lodash";
import isNil from "lodash/isNil";

export class AsUserStore {
  private static readonly STORAGE_AS_USER_KEY: string = "as-user-id";
  private static instance: AsUserStore;

  public static getInstance(): AsUserStore {
    if (!AsUserStore.instance) {
      AsUserStore.instance = new AsUserStore();
    }
    return AsUserStore.instance;
  }

  // Choosing sessionStorage because it allows for the user to have different values in different windows
  // Not a static member since sessionStorage may not exist on the server context
  private get storage(): Storage {
    return sessionStorage;
  }

  public get id(): string | null {
    try {
      const item = this.storage.getItem(AsUserStore.STORAGE_AS_USER_KEY);
      if (!item) return null;
      return item;
    } catch (e) {
      console.error(e);
      this.storage.removeItem(AsUserStore.STORAGE_AS_USER_KEY);
      // Fail gracefully
      return null;
    }
  }

  public set id(value: string | null | undefined) {
    try {
      if (isNil(value)) {
        this.storage.removeItem(AsUserStore.STORAGE_AS_USER_KEY);
        return;
      }
      if (!isString(value)) {
        console.error(`Cannot set a ${typeof value} to As User Id`);
        return;
      }
      const item = this.storage.setItem(AsUserStore.STORAGE_AS_USER_KEY, value);
    } catch (e) {
      console.error(e);
    }
  }
}
