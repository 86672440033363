export default function GenerateMediaIcon({ className = "" }: any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3333 1.75C13.6562 1.75 13.9428 1.95657 14.0448 2.26283L14.5924 3.90551C14.8288 4.6147 15.3853 5.1712 16.0945 5.40759L17.7372 5.95515C18.0434 6.05724 18.25 6.34384 18.25 6.66667C18.25 6.98949 18.0434 7.27609 17.7372 7.37818L16.0945 7.92574C15.3853 8.16213 14.8288 8.71863 14.5924 9.42782L14.0448 11.0705C13.9428 11.3768 13.6562 11.5833 13.3333 11.5833C13.0105 11.5833 12.7239 11.3768 12.6218 11.0705L12.0743 9.42782C11.8379 8.71863 11.2814 8.16213 10.5722 7.92574L8.9295 7.37818C8.62324 7.27609 8.41667 6.98949 8.41667 6.66667C8.41667 6.34384 8.62324 6.05724 8.9295 5.95515L10.5722 5.40759C11.2814 5.1712 11.8379 4.6147 12.0743 3.90551L12.6218 2.26283C12.7239 1.95657 13.0105 1.75 13.3333 1.75ZM11.4583 6.66667C12.2841 7.05153 12.9485 7.71588 13.3333 8.54169C13.7182 7.71588 14.3826 7.05153 15.2084 6.66667C14.3826 6.28181 13.7182 5.61745 13.3333 4.79165C12.9485 5.61745 12.2841 6.28181 11.4583 6.66667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.75C8.32282 8.75 8.60943 8.95657 8.71151 9.26283L9.04005 10.2484C9.15203 10.5844 9.41563 10.848 9.75156 10.96L10.7372 11.2885C11.0434 11.3906 11.25 11.6772 11.25 12C11.25 12.3228 11.0434 12.6094 10.7372 12.7115L9.75156 13.04C9.41563 13.152 9.15203 13.4156 9.04005 13.7516L8.71151 14.7372C8.60943 15.0434 8.32282 15.25 8 15.25C7.67718 15.25 7.39057 15.0434 7.28849 14.7372L6.95995 13.7516C6.84797 13.4156 6.58437 13.152 6.24844 13.04L5.26283 12.7115C4.95657 12.6094 4.75 12.3228 4.75 12C4.75 11.6772 4.95657 11.3906 5.26283 11.2885L6.24844 10.96C6.58437 10.848 6.84797 10.5844 6.95995 10.2484L7.28849 9.26283C7.39057 8.95657 7.67718 8.75 8 8.75ZM8 11.4579C7.84681 11.6641 7.66412 11.8468 7.45791 12C7.66412 12.1532 7.84681 12.3359 8 12.5421C8.15319 12.3359 8.33588 12.1532 8.54209 12C8.33588 11.8468 8.15319 11.6641 8 11.4579Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 8.47222C1.75 7.06083 2.89416 5.91667 4.30556 5.91667H5.83333C6.24755 5.91667 6.58333 6.25245 6.58333 6.66667C6.58333 7.08088 6.24755 7.41667 5.83333 7.41667H4.30556C3.72259 7.41667 3.25 7.88925 3.25 8.47222V15.6944C3.25 16.2774 3.72259 16.75 4.30556 16.75H11.5278C12.1107 16.75 12.5833 16.2774 12.5833 15.6944V14.1667C12.5833 13.7525 12.9191 13.4167 13.3333 13.4167C13.7475 13.4167 14.0833 13.7525 14.0833 14.1667V15.6944C14.0833 17.1058 12.9392 18.25 11.5278 18.25H4.30556C2.89416 18.25 1.75 17.1058 1.75 15.6944V8.47222Z"
        fill="currentColor"
      />
    </svg>
  );
}
