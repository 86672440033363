export function formatPhoneNumber(phoneNumber: string) {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  const match = cleaned.match(/^(1|44)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    const intlCode = "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return phoneNumber;
}

export function formatPhoneNumberGmb(phoneNumber: string) {
  const digits = phoneNumber.replace(/\D/g, "");

  let countryCode, mainNumber;
  if (digits.length === 10) {
    countryCode = "1";
    mainNumber = digits;
  } else if (digits.length === 11) {
    countryCode = digits[0];
    mainNumber = digits.substr(1);
  } else {
    throw new Error("Invalid phone number format");
  }
  const formattedMain = mainNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

  return `+${countryCode} ${formattedMain}`;
}

export function unformatPhoneNumber(number: string) {
  number = number?.replace(/\(|\) /g, "");
  number = number?.replace(/\D/g, "");
  return number;
}

export function validatePhoneNumber(phoneNumber: string) {
  return (
    phoneNumber?.replace(/\D/g, "").length === 10 ||
    (phoneNumber?.replace(/\D/g, "").length === 11 &&
      phoneNumber?.replace(/\D/g, "")[0] === "1")
  );
}

export function validatePhoneNumberNoPlusOne(phoneNumber: string) {
  return (
    phoneNumber?.replace(/\D/g, "").length === 10 ||
    phoneNumber?.replace(/\D/g, "").length === 11
  );
}

export function validateEmail(email: string) {
  return email?.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}
