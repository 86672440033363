import axios from "axios";
import { getAuthToken, getUserId } from "../hooks/getAuthUserId";
import { AsUserStore as AsUserStoreSingleton } from "../components/navbar/LandingPageSearch/AsUserStore";
import { AS_USER_HEADER } from "../constants/httpConstants";
const AsUserStore = AsUserStoreSingleton.getInstance();

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_PROSITE_BASE,
});

api.interceptors.request.use(
  async (request) => {
    // set Default Header to all the request that are made from the client end
    // check if the request is made from the client end/ from browser
    let asUserId;
    if (typeof window === "undefined") {
      const authToken = await getAuthToken();
      if (request?.headers && !request.headers.authorization && authToken) {
        request.headers.authorization = `Bearer ${authToken}`;
      }
    } else {
      const authToken = await getAuthToken();
      if (request?.headers && !request.headers.authorization && authToken) {
        request.headers.authorization = `Bearer ${authToken}`;
      }
      asUserId = AsUserStore.id;
    }

    const existingUserId = await getUserId();
    if (existingUserId && asUserId && existingUserId == asUserId) {
      // if we are impersonating ourselves, stop impersonating
      AsUserStore.id = null;
    } else if (request?.headers && asUserId) {
      // pass impersonated user id to the server (will check role)
      request.headers[AS_USER_HEADER] = asUserId;
    }

    return request;
  },
  (error) => {
    console.error("this is interceptors error", error);
    return Promise.reject(error);
  }
);
