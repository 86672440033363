export default function RewriteIcon({ className }: any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3571 3.47624C17.0555 3.17459 16.5664 3.17459 16.2647 3.47624L15.4249 4.3161L16.5173 5.40849L17.3571 4.56863C17.6588 4.26698 17.6588 3.7779 17.3571 3.47624ZM15.4566 6.46915L14.3642 5.37676L4.59787 15.1431C4.25671 15.4843 4.00594 15.905 3.8682 16.3674L3.6146 17.2188L4.46594 16.9651C4.92833 16.8274 5.34911 16.5766 5.69027 16.2355L15.4566 6.46915ZM15.2041 2.41558C16.0915 1.52814 17.5303 1.52814 18.4178 2.41558C19.3052 3.30302 19.3052 4.74185 18.4178 5.62929L6.75093 17.2961C6.23355 17.8135 5.59541 18.1938 4.89417 18.4027L2.71413 19.0521C2.45021 19.1307 2.16442 19.0584 1.96969 18.8637C1.77496 18.6689 1.70261 18.3831 1.78123 18.1192L2.43063 15.9392C2.63951 15.238 3.01983 14.5998 3.53721 14.0824L15.2041 2.41558Z"
        fill="#2563EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.41669 1.75C5.73951 1.75 6.02611 1.95657 6.1282 2.26283L6.51149 3.41271C6.65457 3.84195 6.9914 4.17878 7.42064 4.32186L8.57052 4.70515C8.87678 4.80724 9.08335 5.09384 9.08335 5.41667C9.08335 5.73949 8.87678 6.02609 8.57052 6.12818L7.42064 6.51147C6.9914 6.65455 6.65457 6.99138 6.51149 7.42063L6.1282 8.5705C6.02611 8.87676 5.73951 9.08333 5.41669 9.08333C5.09386 9.08333 4.80726 8.87676 4.70517 8.5705L4.32188 7.42063C4.1788 6.99138 3.84197 6.65455 3.41273 6.51147L2.26285 6.12818C1.95659 6.02609 1.75002 5.73949 1.75002 5.41667C1.75002 5.09384 1.95659 4.80724 2.26285 4.70515L3.41273 4.32186C3.84197 4.17878 4.1788 3.84195 4.32188 3.41271L4.70517 2.26283C4.80726 1.95657 5.09386 1.75 5.41669 1.75ZM5.41669 4.56582C5.63851 4.90502 5.92834 5.19484 6.26753 5.41667C5.92834 5.6385 5.63851 5.92832 5.41669 6.26751C5.19486 5.92832 4.90503 5.6385 4.56584 5.41667C4.90503 5.19484 5.19486 4.90502 5.41669 4.56582Z"
        fill="#2563EB"
      />
    </svg>
  );
}
