export default function SquarePlusIcon({ className = "" }: any) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 2C0 0.89543 0.895431 0 2 0H3.5C4.60457 0 5.5 0.895431 5.5 2V3.5C5.5 4.60457 4.60457 5.5 3.5 5.5H2C0.89543 5.5 0 4.60457 0 3.5V2ZM2 1C1.44772 1 1 1.44772 1 2V3.5C1 4.05228 1.44772 4.5 2 4.5H3.5C4.05228 4.5 4.5 4.05228 4.5 3.5V2C4.5 1.44772 4.05228 1 3.5 1H2ZM6.5 2C6.5 0.89543 7.39543 0 8.5 0H10C11.1046 0 12 0.895431 12 2V3.5C12 4.60457 11.1046 5.5 10 5.5H8.5C7.39543 5.5 6.5 4.60457 6.5 3.5V2ZM8.5 1C7.94772 1 7.5 1.44772 7.5 2V3.5C7.5 4.05228 7.94772 4.5 8.5 4.5H10C10.5523 4.5 11 4.05228 11 3.5V2C11 1.44772 10.5523 1 10 1H8.5ZM0 8.5C0 7.39543 0.895431 6.5 2 6.5H3.5C4.60457 6.5 5.5 7.39543 5.5 8.5V10C5.5 11.1046 4.60457 12 3.5 12H2C0.89543 12 0 11.1046 0 10V8.5ZM2 7.5C1.44772 7.5 1 7.94772 1 8.5V10C1 10.5523 1.44772 11 2 11H3.5C4.05228 11 4.5 10.5523 4.5 10V8.5C4.5 7.94772 4.05228 7.5 3.5 7.5H2ZM9.25 6.5C9.52614 6.5 9.75 6.72386 9.75 7V8.75H11.5C11.7761 8.75 12 8.97386 12 9.25C12 9.52614 11.7761 9.75 11.5 9.75H9.75V11.5C9.75 11.7761 9.52614 12 9.25 12C8.97386 12 8.75 11.7761 8.75 11.5V9.75H7C6.72386 9.75 6.5 9.52614 6.5 9.25C6.5 8.97386 6.72386 8.75 7 8.75H8.75V7C8.75 6.72386 8.97386 6.5 9.25 6.5Z"
        fill="#2563EB"
      />
    </svg>
  );
}
