import { getSession } from "next-auth/react";
import jwt_decode from "jwt-decode";

interface IDToken {
  id_token: string;
  sub: string;
}

const USER_ID_KEY = "userId";

export const getAuthToken = async () => {
  const session = await getSession();
  if (session) {
    const id_token = session?.accessToken || "";
    return id_token;
  }
};

export const nextAuthUserId = async () => {
  const session = await getSession();
  const id_token = session?.accessToken || "";

  if (id_token) {
    const id_token_decoded: IDToken = jwt_decode<IDToken>(id_token as string);
    const userId = id_token_decoded.sub;
    return userId;
  }
  return "";
};

export async function getUserId() {
  if (typeof window !== "undefined") {
    // check if we have a userId in local storage
    const userId = window.localStorage.getItem(USER_ID_KEY);
    if (userId) {
      return JSON.parse(userId);
    }
  }
  // if we don't have a userId in local storage, check if we have a session
  const session = await getSession();
  if (session) {
    const id_token = session?.accessToken || "";
    if (id_token) {
      const id_token_decoded: IDToken = jwt_decode<IDToken>(id_token as string);
      const userId = id_token_decoded.sub;
      window.localStorage.setItem(USER_ID_KEY, JSON.stringify(userId));
      return userId;
    }
  }
  return null;
}

export async function getUserIdOnVerify() {
  const nextAuthID = await nextAuthUserId();
  if (nextAuthID) {
    window.localStorage.setItem(USER_ID_KEY, JSON.stringify(nextAuthID));
    return nextAuthID;
  } else {
    // if we still don't have a userId, redirect to login
    return null;
  }
}
