import { api } from "../../helpers/topline-api";

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

export function getEmailPlan(subscription: any) {
  try {
    const emailPlanItem = subscription.items.data.find(
      (item: any) => !!item.price.metadata.email
    );
    const emailPlanId = emailPlanItem.id;
    const emailPlanPriceId = emailPlanItem.price.id;
    const emailPlanQuantity = emailPlanItem.quantity;

    return {
      id: emailPlanId,
      price: emailPlanPriceId,
      quantity: emailPlanQuantity,
    };
  } catch (e) {
    return null;
  }
}

export async function stripeGetCustomer(customerId: string) {
  try {
    const response = await api.post(`/api/stripe-get-customer`, { customerId });
    if (response.status > 201) {
      return "error";
    }
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function getInvoices() {
  try {
    const response = await api.get(`/api/stripe-get-invoices`);
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function getPaymentMethodInfo(paymentMethod: string) {
  try {
    const response = await api.post(`/api/stripe-get-payment-info`, {
      paymentMethod,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function payInvoice(invoiceId) {
  try {
    const response = await api.post(`/api/stripe-pay-invoice`, {
      invoiceId,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function updateSubscriptionPm(subscriptionId) {
  try {
    const response = await api.post(`/api/stripe-update-subscription-pm`, {
      subscriptionId,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function stripeAddCard(customerId, paymentMethodInfo) {
  try {
    const response = await api.post(`/api/stripe-add-card`, {
      customerId,
      paymentMethodInfo,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function stripeAddSecondaryCard(
  customerId: string,
  paymentMethodInfo: any
) {
  const response = await api.post(`/api/stripe-add-secondary-card`, {
    customerId,
    paymentMethodInfo,
  });
  return response.data;
}

export async function createStripeCustomer(email, name, phone) {
  try {
    const response = await api.post(`/api/stripe-create-customer`, {
      email,
      name,
      phone,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function stripeGetSubscription(subscriptionId) {
  try {
    const response = await api.post(`/api/stripe-get-subscription`, {
      subscriptionId,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function stripeCreateSubscription(
  customerId,
  landingPageId,
  items
) {
  try {
    const response = await api.post(`/api/stripe-create-subscription`, {
      customerId,
      landingPageId,
      items,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function stripeUpdateSubscription(
  subscriptionId: string,
  landingPageId: string,
  items: any
) {
  try {
    const response = await api.post(`/api/stripe-update-subscription`, {
      subscriptionId,
      landingPageId,
      items,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function stripeCancelTrial(subscriptionId: string) {
  try {
    const response = await api.post(`/api/stripe-cancel-trial`, {
      subscriptionId,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function stripeGetCanceledPlan(customerId) {
  try {
    const response = await api.post(`/api/stripe-get-canceled-plan`, {
      customerId,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function stripeCreateCheckoutSession(
  plan: string,
  couponId: string,
  successUrl: string,
  cancelUrl: string,
  landingPageId: string
) {
  try {
    const response = await api.post(`/api/stripe-create-checkout-session`, {
      plan,
      couponId,
      successUrl,
      cancelUrl,
      landingPageId,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}
