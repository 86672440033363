import React from "react";

interface RejectCircleIconProps {
  size?: number;
  color?: string;
}

export const RejectCircleIcon: React.FC<RejectCircleIconProps> = ({
  size = 18,
  color = "currentColor",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6004 3.71652L14.2835 13.3996C16.5379 10.6986 16.3972 6.67452 13.8614 4.13864C11.3255 1.60276 7.30143 1.46205 4.6004 3.71652ZM13.3996 14.2835L3.71652 4.6004C1.46205 7.30143 1.60276 11.3255 4.13864 13.8614C6.67452 16.3972 10.6986 16.5379 13.3996 14.2835ZM3.25476 3.25476C6.42777 0.0817476 11.5722 0.0817476 14.7452 3.25476C17.9183 6.42777 17.9183 11.5722 14.7452 14.7452C11.5722 17.9183 6.42777 17.9183 3.25476 14.7452C0.0817476 11.5722 0.0817476 6.42777 3.25476 3.25476Z"
        fill={color}
      />
    </svg>
  );
};
