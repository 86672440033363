import React, { useState } from "react";
import {
  ExternalLinkIcon,
  PlusIcon,
  PlusCircleIcon,
  TrashIcon as TrashOutlineIcon,
  UploadIcon,
  DownloadIcon,
  ShareIcon,
  ScissorsIcon,
  CheckCircleIcon,
  EyeOffIcon,
  ChatAltIcon,
  PhotographIcon,
  RefreshIcon,
  SparklesIcon,
  CogIcon,
  PaperAirplaneIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import {
  ArrowRightIcon,
  ChatIcon,
  CheckIcon,
  MailIcon,
  TrashIcon,
  ViewGridIcon,
  ChevronLeftIcon,
} from "@heroicons/react/solid";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import GenerateMediaIcon from "../../primaryrender/editor/NewPost/GenerateMediaIcon";
import RewriteIcon from "../../primaryrender/editor/NewPost/RewriteIcon";
import SquarePlusIcon from "../../ProjectShowcases/SquarePlusIcon";
import { RejectCircleIcon } from "../../common/Icons/RejectCircleIcon/RejectCircleIcon";

export interface PrimaryLinkProps {
  /**
   * most helpful are self alignment utility classes - self-end, self-start etc
   */
  alignment?: string;
  text: string;
  size: string;
  onClickFunc?:
    | React.MouseEventHandler<HTMLButtonElement>
    | ((_event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>);
  width?: string;
  height?: string;
  spacing?: string;
  icon?: string | JSX.Element;
  iconPlacement?: string;
  id?: string;
  customStyle?: string;
  textColor?: string;
  saveDisabled?: boolean;
  loading?: boolean;
  trailingElement?: any;
  customIconStyle?: string;
  useLocalLoadingState?: boolean;
}

export default function PrimaryLink({
  text,
  size,
  onClickFunc,
  icon,
  id,
  customStyle,
  textColor,
  saveDisabled,
  loading,
  iconPlacement = "leading",
  trailingElement,
  customIconStyle,
  useLocalLoadingState = false,
}: PrimaryLinkProps): React.ReactElement {
  let style = `text-${textColor ? textColor : "blue"}-600 hover:text-${
    textColor ? textColor : "blue"
  }-800 transition-colors duration-150 ease-in-out font-medium`;
  let textSize = "";
  const [localLoading, setLocalLoading] = useState(false);

  switch (size) {
    case "sm":
      textSize = "text-xs";
      break;
    case "md":
      textSize = "text-sm";
      break;
    case "lg":
      textSize = "text-base";
      break;
  }

  function getIconStyle(size: string) {
    if (size === "sm" || size === "md")
      return `h-4 w-4 ${customIconStyle && customIconStyle}`;
    return `h-5 w-5 ${customIconStyle && customIconStyle}`;
  }

  if (icon) {
    switch (icon) {
      case "reject-icon":
        icon = <RejectCircleIcon className={getIconStyle(size)} />;
        break;
      case "share":
        icon = <ShareIcon className={getIconStyle(size)} />;
        break;
      case "chat":
        icon = <ChatIcon className={getIconStyle(size)} />;
        break;
      case "mail":
        icon = <MailIcon className={getIconStyle(size)} />;
        break;
      case "external-link":
        icon = <ExternalLinkIcon className={getIconStyle(size)} />;
        break;
      case "plus":
        icon = <PlusIcon className={getIconStyle(size)} />;
        break;
      case "plus-circle":
        icon = <PlusCircleIcon className={getIconStyle(size)} />;
        break;
      case "delete":
        icon = <TrashIcon className={getIconStyle(size)} />;
        break;
      case "delete-outline":
        icon = <TrashOutlineIcon className={getIconStyle(size)} />;
        break;
      case "upload":
        icon = <UploadIcon className={getIconStyle(size)} />;
        break;
      case "export":
        icon = <DownloadIcon className={getIconStyle(size)} />;
        break;
      case "right-arrow":
        icon = <ArrowRightIcon className={getIconStyle(size)} />;
        break;
      case "view-grid":
        icon = <ViewGridIcon className={getIconStyle(size)} />;
        break;
      case "scissors":
        icon = <ScissorsIcon className={getIconStyle(size)} />;
        break;
      case "check-circle":
        icon = <CheckCircleIcon className={getIconStyle(size)} />;
        break;
      case "eyelash":
        icon = <EyeOffIcon className={getIconStyle(size)} />;
        break;
      case "chat-alt":
        icon = <ChatAltIcon className={getIconStyle(size)} />;
        break;
      case "photograph":
        icon = <PhotographIcon className={getIconStyle(size)} />;
        break;
      case "refresh":
        icon = <RefreshIcon className={`${getIconStyle(size)}`} />;
        break;
      case "sparkles":
        icon = <SparklesIcon className={`${getIconStyle(size)}`} />;
        break;
      case "generateMedia":
        icon = <GenerateMediaIcon className={`h-5 w-5 ${customIconStyle}`} />;
        break;
      case "square-plus":
        icon = <SquarePlusIcon className={`h-5 w-5 ${customIconStyle}`} />;
        break;
      case "rewrite":
        icon = <RewriteIcon className={`${getIconStyle(size)}`} />;
        break;
      case "check":
        icon = <CheckIcon className={`${getIconStyle(size)}`} />;
        break;
      case "settings":
        icon = <CogIcon className={`${getIconStyle(size)}`} />;
        break;
      case "paper-plane":
        icon = <PaperAirplaneIcon className={`${getIconStyle(size)}`} />;
        break;
      case "send-paper-plane":
        icon = (
          <PaperAirplaneIcon
            className={`${getIconStyle(size)} transform rotate-45`}
          />
        );
        break;
      case "pencil":
        icon = <PencilIcon className={`${getIconStyle(size)}`} />;
        break;
      case "back":
        icon = <ChevronLeftIcon className={`${getIconStyle(size)}`} />;
    }
  }

  if (customStyle) {
    style = style + " " + customStyle;
  }

  async function onClickFuncWithLocalLoading(
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> {
    if (onClickFunc) {
      setLocalLoading(true);
      await onClickFunc(e);
      setLocalLoading(false);
    }
  }

  return (
    <>
      <button
        id={id || ""}
        data-cy={id}
        className={`${textSize} ${style} flex items-center cursor-pointer ${
          saveDisabled && "cursor-not-allowed opacity-75"
        }`}
        onClick={
          useLocalLoadingState ? onClickFuncWithLocalLoading : onClickFunc
        }
        disabled={saveDisabled}
      >
        {!loading && !localLoading && icon && iconPlacement !== "lagging" && (
          <div className="mr-1">{icon}</div>
        )}

        {loading || localLoading ? <LoadingSpinner color="blue" /> : text}
        {trailingElement && trailingElement}
        {icon && iconPlacement === "lagging" && (
          <div className="flex items-center ml-1 h-full">{icon}</div>
        )}
      </button>
    </>
  );
}
